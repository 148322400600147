/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { findBySlug, getContentfulPageSectionListData } from "../helpers"

import Column from "../components/column"
import Form from "../components/form"
import Container from "../components/container"
import Page from "../components/page"
import Row from "../components/row"
import Text from "../components/text"
import { paramsCheck } from "../functions/functions"
import RichText from "../components/rich-text"
import get from "lodash/get"


const TourRequest = ({ data }) => {
  paramsCheck()
  const [screenWidth, setScreenWidth] = useState("")
  const mainFormData = getContentfulPageSectionListData({
    sectionList: data.contentfulPage.sectionList,
    sectionSlug: "page-section-tour-request-main-form",
  })

  if (mainFormData.detailField)
    mainFormData.defaultFields = {
      ...mainFormData.defaultFields,
      [mainFormData.detailField]: `Tour Request Form`,
    }

  if (mainFormData.conversionDetail)
    mainFormData.conversionDetail = "_xAMCIL22NwBEJeLhv0C"

    useEffect(() => {
      if (checkIfWindow) {
        setWindowWidth()
      }
    }, [])
    
    const setWindowWidth = () => {
      setScreenWidth(window.screen.availWidth)
    }
  
    const checkIfWindow = () => {
      if (typeof window !== "undefined") {
        window.addEventListener("orientationchange", setWindowWidth)
        return typeof window !== "undefined"
      }
      return typeof window !== "undefined"
    }

  const renderTourRequest = () => {
    const contactFormBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "tour-request-form-module",
    })
    if (!contactFormBladePatternData) return
    const description = get(
      contactFormBladePatternData,
      "contentCards[0].description"
    )
    return (
      <Container
        customStyle={{
          py: theme => [
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
            theme.spacing.vertical.lg,
          ],
        }}
      >
        {mainFormData && checkIfWindow() && (
          <Form
            customStyle={{ px: 0 }}
            formData={mainFormData}
            moveToTopOnSubmit={true}
            noSteps={true}
            type="secondary"
            setWidth={(screenWidth >= 768) ? "50%" : "100%"}
          >
            <Row customStyle={{ pb: 4 }}>
              <Column customStyle={{ maxWidth: 500 }}>
                <Text type="h1">{contactFormBladePatternData.title}</Text>
              </Column>
            </Row>
            <Row customStyle={{ pb: 5 }}>
              <Column customStyle={{ maxWidth: 500 }}>
                
                <RichText data={description} />
              </Column>
            </Row>
          </Form>
        )}
      </Container>
    )
  }

  return <Page data={data.contentfulPage}>{renderTourRequest()}</Page>
}

export default TourRequest

export const query = graphql`
  query tourRequestQuery {
    contentfulPage(slug: { eq: "tour-request" }) {
      ...PageData
      ...BladePatternData
    }
  }
`
